
import Btn from "@/components/Btn.vue";
import Card from "@/components/Card.vue";
import DatePicker from "@/components/DatePicker.vue";
import GenderSelector from "@/components/GenderSelector.vue";
import LangSelect from "@/components/LangSelect.vue";
import Logo from "@/components/Logo.vue";
import Modal from "@/components/Modal.vue";
import TermsModalContent from "@/components/TermsModalContent.vue";
import { getToday } from "@/plugins/moment";
import gql from "graphql-tag";
import _ from "lodash";
import validator from "validator";
import { Component, Vue } from "vue-property-decorator";
import { Mutation } from "vuex-class";
import "../assets/images/logo.svg";
import { RoutesNames } from "../router";

@Component({
  components: {
    Card,
    Btn,
    LangSelect,
    Logo,
    DatePicker,
    GenderSelector,
    Modal,
    TermsModalContent,
  },
})
export default class SignupView extends Vue {
  newUser = {
    name: null,
    surname: null,
    email: null,
    phone: null,
    birthday: null,
    gender: null,
    taxCode: null,
    privacyCheck: false,
  };
  loading = false;
  isFormValid = false;

  nameRules = [(v) => !!v || "Inserire un nome"];
  surnameRules = [(v) => !!v || "Inserire il cognome"];
  emailRules = [
    (v) => !!v || "Inserire la mail",
    (v) => !!validator.isEmail(v ? v : "") || "Email non valida",
  ];
  phoneRules = [(v) => !!v || "Inserire un numero di telefono"];
  genderRules = [(v) => !!v || "Seleziona un valore"];
  birthdayRules = [(v) => !!v || "Inserire la data di nascita"];
  taxCodeRules = [(v) => !!v || "Inserire il codice fiscale"];
  privacyRules = [(v) => !!v || "Accettare la Privacy Policy"];

  @Mutation showError;
  @Mutation showSuccess;

  showFinalMessage = false;
  showPrivacyModal = false;

  mounted() {
    this.$store.commit("setFillHeightOnMainContainer", true);
    this.resetValues();
  }

  resetValues() {
    (this.$refs.createUserForm as Vue & { reset: () => void })?.reset();
  }

  getCopy() {
    return (
      "© " +
      new Date().getFullYear() +
      " CNA™ - " +
      this.$t("allRightsReserved")
    );
  }

  getUserMaxDate() {
    return getToday();
  }

  goToLogin() {
    this.$router.push({ name: RoutesNames.login });
  }

  async signup() {
    if (
      (
        this.$refs.createUserForm as Vue & {
          validate: () => boolean;
        }
      ).validate()
    ) {
      this.loading = true;
      console.log(this.newUser);

      const res = await this.$apollo.mutate({
        mutation: gql`
          mutation insertCandidateUser(
            $email: String
            $name: String
            $surname: String
            $phone: String
            $birthday: Date
            $gender: String
            $taxCode: String
          ) {
            insertCandidateUser(
              email: $email
              name: $name
              surname: $surname
              phone: $phone
              birth_date: $birthday
              gender: $gender
              tax_code: $taxCode
            )
          }
        `,
        variables: {
          email: this.newUser.email,
          name: _.capitalize(this.newUser.name),
          surname: _.capitalize(this.newUser.surname),
          phone: this.newUser.phone,
          birthday: this.newUser.birthday,
          gender: this.newUser.gender,
          taxCode: this.newUser.taxCode,
        },
      });
      if (res.data.insertCandidateUser) {
        this.showSuccess("Richiesta di registrazione completata");
        this.resetValues();
        this.showFinalMessage = true;
      } else {
        this.showError("Errore durante richiesta di registrazione");
      }
      this.loading = false;
    }
  }

  openPrivacyModal(event) {
    event.preventDefault();
    this.showPrivacyModal = true;
  }
}
